import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../authentication.service";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import * as $ from "jquery";

@Component({
  selector: "app-landing",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  constructor(private auth: AuthenticationService,private router: Router) {}
  condition: any;
  accesslevel2: string;
  header: string;
  title = "Neuron";
  email = localStorage.getItem('email');
  showcomputermenu : boolean = false;
  shownlpmenu: boolean = false;
  showsoundmenu: boolean = false;
  showautomlmenu: boolean = false;
  showtelescopemenu: boolean = false;
  showrecommendationmenu : boolean = false;
  showgenerativemenu : boolean = false;
  showtimeseriesmenu : boolean = false;
  showanomalydetection : boolean = false;

  applicationidarray : any = [] ;
  activemenu : string = '';


  computerdata: any = [];
  coviddata: any = [];
  nlpdata: any = [];
  sounddata: any = [];
  automl: any = [];
  telescope: any = [];
  recommendation: any = [];
  genrativeai: any = [];
  timeseries: any = [];
  anomalydetection: any = [];


  // computerdata = [
  //   {
  //     id: 0,
  //     application_id :1,
  //     projectname: "Corrosion Detection",
  //     descriptiontext:
  //       "Identify damages like corrosion by analyzing images of car parts.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/2_corrosion detection.jpg",
  //     link: "http://corrosiondetection.affineanalytics.ai/",
  //     color: "#27d29e",
  //     industry: "Automobile",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Corrosion Detection.pptx",
  //     pdf: "assets/pdf/Corrosion Detection.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 1,
  //     application_id :2,
  //     projectname: "Damage Detection",
  //     descriptiontext:
  //       "Identify damages like mechanical dents and abrasions by analyzing images of a given car.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/icon 1.jpg",
  //     link: "http://damagedetection.affineanalytics.ai/",
  //     color: "#27d29e",
  //     industry: "Automobile, Manufacturing",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Damage Detection.pptx",
  //     pdf: "assets/pdf/Damage Detection.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 2,
  //     application_id :3,
  //     projectname: "Fabric Inspection-Yarn",
  //     descriptiontext:
  //       "Identify damages and locate them accurately by analyzing a video stream on an edge device",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Fabric_inspection.jpg",
  //     link: "http://fabric-inspection.affineanalytics.ai/",
  //     color: "#27d29e",
  //     industry: "CPG, Textile",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Fabric Inspection-Yarn.pptx",
  //     pdf: "assets/pdf/Fabric Inspection-Yarn.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 3,
  //     application_id :4,
  //     projectname: "Fabric Inspection-Personal Care",
  //     descriptiontext:
  //       "Identify type of damages and measure quality index for a given image of santipary pads",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Fabric_inspection.jpg",
  //     link: "http://femcaredefectdetection.affineanalytics.ai/",
  //     color: "#27d29e",
  //     industry: "CPG",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Fabric Inspection-Personal Care.pptx",
  //     pdf: "assets/pdf/Fabric Inspection-Personal Care.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 4,
  //     application_id :5,
  //     projectname: "Software Testing",
  //     descriptiontext:
  //       "Using computer vision and OCR to automate UI testing process",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/7-software testing.jpg",
  //     link: "http://softwaretesting.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Software",
  //     function: "Ui Testing",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Software Testing.pptx",
  //     pdf: "assets/pdf/Software Testing.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 5,
  //     application_id :6,
  //     projectname: "Digitization of Physical Documents",
  //     descriptiontext:
  //       "Using OCR to extract relevant information and store them digitally in a database",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Digitalising documents.jpg",
  //     link: "http://documentocr.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Technology",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     pdf: "assets/pdf/Document Digitization.pdf",
  //     ppt: "assets/ppt/Document Digitization.pptx",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },

  //   {
  //     id: 6,
  //     application_id :7,
  //     projectname: "Satellite Image Segmentation",
  //     descriptiontext:
  //       "Using computer vision to extract and highlight features from satellite imagery to  provide accurate inputs for catastrophe risk estimation",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Chatbot on HR Policies.jpg",
  //     link: "http://satellitesegmentation.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Insurance",
  //     function: "Risk Management & Profiling",
  //     buttonstate: false,
  //     pdf: "assets/pdf/Geographical feature segmentation on satellite images.pdf",
  //     ppt: "assets/ppt/Geographical feature segmentation on satellite images.pptx",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },

  //   {
  //     id: 7,
  //     application_id :8,
  //     projectname: "Emotion Detection",
  //     descriptiontext: "Using computer vision to identify facial expression",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/emotion_detection.jpg",
  //     link: "http://emotion-detection.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Any Domain",
  //     function: "Customer Experience",
  //     buttonstate: false,
  //     pdf: "assets/pdf/Emotion Recognition.pdf",
  //     ppt: "assets/ppt/Emotion Recognition.pptx",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 8,
  //     application_id :9,
  //     projectname: "Facial Recognition",
  //     descriptiontext:
  //       "Using computer vision to recognize face for attendance and security systems",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/9-face-recognition.jpg",
  //     link: "http://facerecognition.affineanalytics.ai",
  //     industry: "Any Domain",
  //     function: "Customer Experience",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Facial Recognition.pptx",
  //     pdf: "assets/pdf/Facial Recognition.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 9,
  //     application_id :10,
  //     projectname: "In-store Analytics",
  //     descriptiontext:
  //       "Using computer vision to analyze store traffic , shelf inventory and market basket analysis",
  //     buttontext: "Video",
  //     imagepath: "assets/Icons/4_instore analytics.jpg",
  //     link: "http://in-storeanalytics.affineanalytics.ai/",
  //     color: "#726cdf",
  //     industry: "CPG, Retail",
  //     function: "In-Store Operations",
  //     buttonstate: false,
  //     ppt: "assets/ppt/In-store Analytics.pptx",
  //     pdf: "assets/pdf/In-store Analytics.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 10,
  //     application_id :11,
  //     projectname: "Medical Imaging",
  //     descriptiontext:
  //       'Analyze radiology images and identify presence of "Pneumonia"',
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/8-medical imaging.jpg",
  //     link: "http://medicalimaging.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Hospital, Healthcare, Medicine",
  //     function: "Radiology",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Medical Imaging.pptx",
  //     pdf: "assets/pdf/Medical Imaging.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 11,
  //     application_id :12,
  //     projectname: "Parking System",
  //     descriptiontext:
  //       "Alert users about parking availability . Identify violation of no-parking zone if any",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/6_parking system.jpg",
  //     link: "http://parkingmanagementsystem.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Smart City, Real Estate",
  //     function: "Resource Utilization",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Parking System.pptx",
  //     pdf: "assets/pdf/Parking System.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 12,
  //     application_id :13,
  //     projectname: "Traffic Police Monitoring",
  //     descriptiontext:
  //       "Optimize traffic lights timings to reduce congestion and regualirize smooth traffic flow",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/_5traffic police monitoring.jpg",
  //     link: "http://trafficpolicemonitoring.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Smart City, Traffic Police",
  //     function: "Traffic Operations",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Traffic Management.pptx",
  //     pdf: "assets/pdf/Traffic Management.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   // { 'id': 12, 'projectname': 'Satelite Segmentation', 'descriptiontext': 'Detect and classify features like buildings, tress etc. from Satellite Imagery using Semantic Segmentation', 'buttontext': 'Demo', 'imagepath': 'assets/Icons/_5traffic police monitoring.jpg', 'link': 'http://satellitesegmentation.affineanalytics.ai/', 'color': '#050c42', 'industry': 'Smart City, Traffic Police', 'function': 'Traffic Operations', 'buttonstate': false, 'ppt': 'assets/ppt/Traffic Management.pptx', 'pdf': 'assets/pdf/Traffic Management.pdf', 'pptbutton' : true,  'pdfbutton' : true},
  //   {
  //     id: 13,
  //     application_id :14,
  //     projectname: "Defect Detection",
  //     descriptiontext:
  //       "Identify defects and orientation of a component using Computer Vision",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/icon 1.jpg",
  //     link: "http://microscopicdefectdetection.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "Automobile, Manufacturing",
  //     function: "Quality Assurance",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 14,
  //     application_id :15,
  //     projectname: "Banking Form Recognizer",
  //     descriptiontext:
  //       "Using combined approach of AWS OCR service and NLP functions to extract relevant details from a banking form",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Document Search Engine.jpg",
  //     link: "http://formparser.affineanalytics.ai/",
  //     color: "#050c42",
  //     industry: "BFSI",
  //     function: "Operation",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // coviddata = [
  //   {
  //     id: 0,
  //     application_id :16,
  //     projectname: "Facial Recognition",
  //     descriptiontext:
  //       "Using computer vision to recognize face for attendance and security systems",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/9-face-recognition.jpg",
  //     link: "http://facerecognition.affineanalytics.ai",
  //     industry: "Any Domain",
  //     function: "Customer Experience",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Facial Recognition.pptx",
  //     pdf: "assets/pdf/Facial Recognition.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 1,
  //     application_id :17,
  //     projectname: "Social Distancing",
  //     descriptiontext:
  //       "Using Computer vision to identify and alert public , employees if there are breaching any social distancing norms.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/10-social distance.jpg",
  //     link: "http://socialdistancing.affineanalytics.ai/",
  //     industry: "Technology",
  //     function: "Adminstration",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Covid Dashboard.pptx",
  //     pdf: "assets/pdf/Covid Dashboard.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 2,
  //     application_id :18,
  //     projectname: "Mask Detection Videos",
  //     descriptiontext:
  //       "Using Computer vision to identify possible incidences of violation of face mask regulations.",
  //     buttontext: "Video",
  //     imagepath: "assets/Icons/11-mask detection.jpg",
  //     link: "http://covidmaskdetection.affineanalytics.ai/",
  //     industry: "Healthcare",
  //     function: "Safety",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Covid Dashboard.pptx",
  //     pdf: "assets/pdf/Covid Dashboard.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 3,
  //     application_id :19,
  //     projectname: "Covid Forecasting Dashboard",
  //     descriptiontext:
  //       "Using Machine Learning to predict the population susceptible to Covid-19 virus",
  //     buttontext: "Video",
  //     imagepath: "assets/Icons/12-covid forcasting.jpg",
  //     link: "http://covid19.affineanalytics.co.in/",
  //     industry: "Technology",
  //     function: "Adminstration",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Covid Dashboard.pptx",
  //     pdf: "assets/pdf/Covid Dashboard.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  // ];

  // nlpdata = [
  //   {
  //     id: 0,
  //     application_id :20,
  //     projectname: "Code Generation from NLP",
  //     descriptiontext: "Generating SQL Syntax from Natural Language",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/code generation.jpg",
  //     link: "http://nl2sql.affineanalytics.co.in/",
  //     industry: "IT & Analytics",
  //     function: "Operations",
  //     buttonstate: true,
  //     ppt: "assets/ppt/Code Generation from NLP.pptx",
  //     pdf: "assets/pdf/Code Generation from NLP.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 1,
  //     application_id :21,
  //     projectname: "Document Summarization",
  //     descriptiontext:
  //       "Generating summary of a given document and extracting key entities (keywords)",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/document summarisation.jpg",
  //     link: "http://3.226.15.165:4000/",
  //     color: "#050c42",
  //     industry: "Legal",
  //     function: "Verification",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Document Summarizer.pptx",
  //     pdf: "assets/pdf/Document Summarizer.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 2,
  //     application_id :22,
  //     projectname: "Document Search Engine",
  //     descriptiontext: "Accelerate document search based on user inputs",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Document Search Engine.jpg",
  //     link: "http://document-search-engine.affineanalytics.ai/",
  //     industry: "Any Domain",
  //     function: "Knowledge Management",
  //     buttonstate: false,
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 3,
  //     application_id :23,
  //     projectname: "Text Classification",
  //     descriptiontext:
  //       "Analyzing a given text and identify the relevant team to resolve the text content (greiveiances, tasks etc)",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://textclassification.affineanalytics.ai/",
  //     industry: "Any Domain",
  //     function: "Operation",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Document Classification.pptx",
  //     pdf: "assets/pdf/Document Classification.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 4,
  //     application_id :24,
  //     projectname: "Sentiment Analysis",
  //     descriptiontext:
  //       "Identifying the latent customer sentiments from a given text/abstract",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/sentiment analysis.jpg",
  //     link: "http://sentimentanalysis.affineanalytics.ai/",
  //     industry: "Any Domain",
  //     function: "Marketing",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Sentiment Analysis.pptx",
  //     pdf: "assets/pdf/Sentiment Analysis.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   // { 'id': 5, 'projectname': 'Chatbot for Banking', 'descriptiontext': 'Provide response to customers on queries related to retail banking', 'buttontext': 'Demo', 'imagepath': 'assets/Icons/cahtbot on banking.jpg', 'link':'http://bankingbot.affineanalytics.ai/', 'industry': 'Banking', 'function': 'Customer Services', 'ppt': 'assets/ppt/Banking Chatbot.pptx', 'pdf': 'assets/pdf/Software Testing.pdf', 'buttonstate': false , 'pptbutton' : false , 'pdfbutton' : false },
  //   {
  //     id: 6,
  //     application_id :25,
  //     projectname: "Chatbot on HR Policies",
  //     descriptiontext: "Resolve queries related to HR process and policies",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Chatbot on HR Policies.jpg",
  //     link: "https://teams.microsoft.com/l/app/65bc366d-cf07-45e2-ad8c-53eee2bd4cd7?source=store-copy-link",
  //     industry: "Any Domain",
  //     function: "HR Function",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Chatbot for HR Policies.pptx",
  //     pdf: "assets/pdf/Chatbot for HR Policies.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  //   {
  //     id: 7,
  //     application_id :26,
  //     projectname: "Resume Reader",
  //     descriptiontext:
  //       "Extract key entities from resume and store in a structured format to screen multiple resumes efficiently in less amount of time",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/7-software testing.jpg",
  //     link: "http://resumereader.affineanalytics.ai/",
  //     industry: "Any Domain",
  //     function: "HR function",
  //     buttonstate: false,
  //     ppt: "assets/ppt/Sentiment Analysis.pptx",
  //     pdf: "assets/pdf/Sentiment Analysis.pdf",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // sounddata = [
  //   {
  //     id: 0,
  //     application_id :27,
  //     projectname: "Vibration Analysis",
  //     descriptiontext:
  //       "Identify type of anomalies or defects by analyzing sound generated from machine parts",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/15-vibration analysys.jpg",
  //     link: "http://vibrationanalysis.affineanalytics.ai/",
  //     industry: "Manufacturing",
  //     function: "Predictive Maintenance",
  //     buttonstate: false,
  //     ppt: "assets/ppt/SoundClassification.pptx",
  //     pdf: "assets/pdf/SoundClassification.pdf",
  //     pptbutton: false,
  //     pdfbutton: false,
  //   },
  // ];

  // automl = [
  //   {
  //     id: 0,
  //     application_id :28,
  //     projectname: "MLgam - Bumblebee",
  //     descriptiontext:
  //       "In-house developed tool to automate the complete pipeline from raw dataset to deployable machine learning model within minutes",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/15-vibration analysys.jpg",
  //     link: "https://mlgam.affineanalytics.ai/",
  //     industry: "All",
  //     function: "Data Science",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // telescope = [
  //   {
  //     id: 0,
  //     application_id :29,
  //     projectname: "Telescope",
  //     descriptiontext:
  //       "Telescope is a comprehensive Computer vision tool which can analyze a satellite imagery of a given landscape and generate an overall landscape",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Chatbot on HR Policies.jpg",
  //     link: "https://telescope.affine.ai/#/",
  //     industry: "Oil & Gas, Insurance",
  //     function: "Risk Management & Profiling",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // recommendation = [
  //   {
  //     id: 0,
  //     application_id :30,
  //     projectname: "Reinforcement Learning",
  //     descriptiontext:
  //       "An adaptive self learning system based on advanced reinforcement learning to model personalized item recommendations",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://3.226.15.165:5070/",
  //     industry: "OTT, Gaming, Retail",
  //     function: "Customer Experience",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 1,
  //     application_id :31,
  //     projectname: "GNN Movie Recommendation",
  //     descriptiontext:
  //       "GNN is a recommender system based on graph neural network it generates the list of personalized recommended items for a given user",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://3.226.15.165:5055/",
  //     industry: "E-commerce, Entertainment",
  //     function: "Item Recommendation",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // genrative = [
  //   {
  //     id: 0,
  //     application_id :32,
  //     projectname: "Invoice Toolbox",
  //     descriptiontext:
  //       "Its an invoice based toolbox that can act as an assistant to answer your every query about an invoice , or it can save the invoice in a structured database for further applications.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://documentanalysisai.streamlit.app/",
  //     industry: "E-commerce, Service providers",
  //     function:
  //       "Querying and storing unstructured documents into structured database",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 1,
  //     application_id :33,
  //     projectname: "Email Personalisation",
  //     descriptiontext:
  //       "This app can generate content for the email for marketing campaigns personalised for the user based on the objective of the campaign.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://emailpersonalisation-ae4b8lkqe6q.streamlit.app/",
  //     industry: "Travel sites, Commercial",
  //     function: "Automated email generation",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 2,
  //     application_id :34,
  //     projectname: "QA Bot",
  //     descriptiontext:
  //       "It is a QA bot that takes specific data input of Textual and multimedia form and allows users to query questions specific to the loaded data.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://dolly-affinebot.streamlit.app/",
  //     industry: "Multi-sector",
  //     function:
  //       "Querying from PDF or video format data to find insights from within the data",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 3,
  //     application_id :35,
  //     projectname: "CreativeAI",
  //     descriptiontext:
  //       "This tool can generate marketing creatives from product photos using simple textual inputs and a few clicks",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://creaitive.generax.ai/#/",
  //     industry: "E-commerce, Marketing",
  //     function: "Marketing Creative Generation",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 4,
  //     application_id :36,
  //     projectname: "Quick Insights",
  //     descriptiontext:
  //       "This tool can generate textual insights and plots from tabular data using natural language input.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://localhost:8501",
  //     industry: "Supply Chain, Analytics",
  //     function: "Insight and Summary Generation",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 5,
  //     application_id :37,
  //     projectname: "Eryl",
  //     descriptiontext:
  //       "Eryl is a open AI based QnA bot. It helps quick and effective answers to your questions from various documents.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "http://4.155.58.163/QnA-platform/login",
  //     industry: "Pharma, Healthcare, Legal, Manufacturing, Retail, Ecommerce",
  //     function: "Querying Documents to retrieve appropriate responses",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 6,
  //     application_id :38,
  //     projectname: "Affine Chatbot",
  //     descriptiontext:
  //       "A travel assistant chatbot is a bot capable of guiding the users to search and book a flights and hotels using simple natural conversational language.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://t.me/affineaibot",
  //     industry: "Travel/ Hotels/ others",
  //     function: "flight/ hotel Booking Assistant chatbot",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 7,
  //     application_id :39,
  //     projectname: "Sentiment Analysis",
  //     descriptiontext:
  //       "Stakeholders/Users gain immediate access to real-time quantitative insights into users reaction to a game, allowing for swift decision-making.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://social-media-analytics-affine.streamlit.app/",
  //     industry: "Gaming & Entertainment",
  //     function: "Marketing",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  //   {
  //     id: 8,
  //     application_id :40,
  //     projectname: "Python Code Generator",
  //     descriptiontext:
  //       "A code generator bot built using GPT to generate Python code using natural language input from user.",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/text classification.jpg",
  //     link: "https://pythonbot-lnepvrtujm5smhv52geig6.streamlit.app/",
  //     industry: "IT/Development/others",
  //     function: "Python code generation for development and data analytics.",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  // timeseries = [
  //   {
  //     id: 0,
  //     application_id :41,
  //     projectname: "Time series forecasting app",
  //     descriptiontext:
  //       "The Affine Experimental Time Series Toolbox - a powerful and flexible tool specially designed to efficiently handle multiple time series data. With its advanced capabilities",
  //     buttontext: "Demo",
  //     imagepath: "assets/Icons/Chatbot on HR Policies.jpg",
  //     link: "https://affine-time-series-model.hf.space",
  //     industry: "E-commerce, Energy",
  //     function:
  //       "Forecasting with Temporal Fusion transformer and FbProphet pretrained models for Store product Demand and Energy usage Demand.",
  //     buttonstate: false,
  //     ppt: "",
  //     pdf: "",
  //     pptbutton: true,
  //     pdfbutton: true,
  //   },
  // ];

  searchdata = [];
  searchdata2 = [];
  searchdata3 = [];
  searchdata4 = [];
  searchdata5 = [];
  searchdata6 = [];
  searchdata7 = [];
  searchdata8 = [];
  searchdata9 = [];
  searchdata10 = [];

  searchText = "";

  ngOnInit(): void {
    this.activemenu = '';
    clearAllBodyScrollLocks();
    // sessionStorage.removeItem('header');

    this.getuserdata();
    this.getDynamicCardCreation();
    
    this.accesslevel2 = sessionStorage.getItem("accesslevel");
    const isTrueSet = this.accesslevel2 === "true";
    this.condition = isTrueSet;

    // this.header = 'false';
    
    

    $(document).ready(function () {
      const targetElement = $("#onscroll");

      enableBodyScroll(targetElement);
    });
  }

  searchValue(value: any): void {
    let searchId: string = this.searchText.toLowerCase();
    
    this.searchdata = this.computerdata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata2 = this.nlpdata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata3 = this.sounddata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata4 = this.coviddata.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata5 = this.automl.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata6 = this.telescope.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata7 = this.recommendation.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });
    
    this.searchdata8 = this.genrativeai.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });

    this.searchdata9 = this.timeseries.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });
    this.searchdata10 = this.anomalydetection.filter(function (val, ind, arr) {
      var status =
        (val.projectname !== undefined &&
          val.projectname !== null &&
          val.projectname.toLowerCase().indexOf(searchId) !== -1) ||
        (val.descriptiontext !== undefined &&
          val.descriptiontext !== null &&
          val.descriptiontext.toLowerCase().indexOf(searchId) !== -1) ||
        (val.industry !== undefined &&
          val.industry !== null &&
          val.industry.toLowerCase().indexOf(searchId) !== -1) ||
        (val.function !== undefined &&
          val.function !== null &&
          val.function.toLowerCase().indexOf(searchId) !== -1);
      return status;
    });
  }

  getrepodata() {
    this.searchdata = this.computerdata;
    this.searchdata2 = this.nlpdata;
    this.searchdata3 = this.sounddata;
    this.searchdata4 = this.coviddata;
    this.searchdata5 = this.automl;
    this.searchdata6 = this.telescope;
    this.searchdata7 = this.recommendation;
    this.searchdata8 = this.genrativeai;
    this.searchdata9 = this.timeseries;
    this.searchdata10 = this.anomalydetection;
  }

  openlink(link: string, id: number) {
    // console.log(link)
    // console.log(id)
    if (id) {
      const token = localStorage.getItem('token');
      if (token) {
        const redirectUrl = `${link}?token=${token}`;
        window.open(redirectUrl, '_blank');
      } else {
        // Handle the case when the token is not available
        console.error('Token not found.');
      }
    } else {
      if (link && link.length > 0) {
        window.open(link, '_blank');
      }
    }
  }
  
  
  logout() {
    // Clear the local storage
    localStorage.clear();
    // Redirect to the login page
    this.router.navigateByUrl('/login');

  }

  getuserdata(){
    this.activemenu = '';
    this.auth.userdata(this.email).subscribe(
      (response) => {
        const menuarray = response.menu_name.split(',');
        console.log(menuarray);
        
        this.applicationidarray = response.Access_id.split(',');
        if(menuarray.includes('computerdata')){
          this.showcomputermenu = true; 
          if( this.activemenu == ''){
            this.activemenu = 'computerdata';
          } 
        }
        if(menuarray.includes('nlpdata')){
          this.shownlpmenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'nlpdata';
          } 
        }
        if(menuarray.includes('sounddata')){
          this.showsoundmenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'sounddata';
          } 
        }
        if(menuarray.includes('automl')){
          this.showautomlmenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'automl';
          } 
        }
        if(menuarray.includes('telescope')){
          this.showtelescopemenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'telescope';
          } 
        }
        if(menuarray.includes('recommendation')){
          this.showrecommendationmenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'recommendation';
          } 
        }
        if(menuarray.includes('genrativeai')){
          this.showgenerativemenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'genrativeai';
          }
         
        }
        if(menuarray.includes('timeseries')){
          this.showtimeseriesmenu = true;
          if( this.activemenu == ''){
            this.activemenu = 'timeseries';
          } 
        }
        if(menuarray.includes('anomalydetection')){
          this.showanomalydetection = true;
          if( this.activemenu == ''){
            this.activemenu = 'anomalydetection';
          } 
        }
        this.getrepodata();
      },
      (err) => {
        console.log(err)
      }
    );
  }

  getDynamicCardCreation(){
      this.auth.dynamicCardCreation()
      .subscribe(
          (response) => {
            // console.log("response:",response);
            response.forEach((ele:any) => {
              if(ele.menu_name == 'computerdata'){
                this.computerdata = response.filter(ele => ele.menu_name == 'computerdata' && ele.id < 16);
              }
              if(ele.menu_name == 'computerdata'){
                this.coviddata = response.filter(ele => ele.menu_name == 'computerdata' && ele.id >= 16);
              }
              if(ele.menu_name == 'nlpdata'){
                this.nlpdata = response.filter(ele => ele.menu_name == 'nlpdata');
              }
              if(ele.menu_name == 'sounddata'){
                this.sounddata = response.filter(ele => ele.menu_name == 'sounddata');
              }
              if(ele.menu_name == 'automl'){
                this.automl = response.filter(ele => ele.menu_name == 'automl');
              }
              if(ele.menu_name == 'telescope'){
                this.telescope = response.filter(ele => ele.menu_name == 'telescope');
              }
              if(ele.menu_name == 'recommendation'){
                this.recommendation = response.filter(ele => ele.menu_name == 'recommendation');
              }
              if(ele.menu_name == 'genrativeai'){
                this.genrativeai = response.filter(ele => ele.menu_name == 'genrativeai');
              }
              if(ele.menu_name == 'timeseries'){
                this.timeseries = response.filter(ele => ele.menu_name == 'timeseries');
              }
              if(ele.menu_name == 'anomalydetection'){
                this.anomalydetection = response.filter(ele => ele.menu_name == 'anomalydetection');
              }
            });
            this.getrepodata();
          },
          (err) => {
            console.log(err)
          }
      );
  }
}
