import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

export interface UserDetails {
  id: number;
  name: string;
  companyname: string;
  email: string;
  jobtitle: string;
  password: string;
  created: Date;
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
}

export interface TokenPayload {
  id: number;
  name: string;
  companyname: string;
  email: string;
  jobtitle: string;
  password: string;
  created: Date;
}

@Injectable()
export class AuthenticationService {
  private token: string;

  constructor(private http: HttpClient, private router: Router) {}

  private saveToken(token: string): void {
    localStorage.setItem("usertoken", token);
    this.token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem("usertoken");
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split(".")[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  public register(user: TokenPayload): Observable<any> {
    return this.http.post(`/users/register`, user);
  }

  public login(user: TokenPayload): Observable<any> {
    const base = this.http.post(`/users/login`, user);

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }

  public userdata(email: any): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/userdata?email=${email}`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data: TokenResponse) => {
        // if (data.token) {
        //   this.saveToken(data.token);
        // }
        return data;
      })
    );

    return request;
  }

  public dynamicCardCreation(): Observable<any> {
    // Construct the URL with query parameters
    const url = `/users/dynamicCardCreation`;

    // Use HTTP GET request instead of POST
    const base = this.http.get(url);

    const request = base.pipe(
      map((data) => {
        return data;
      })
    );

    return request;
  }

  public logout(): void {
    this.token = "";
    window.localStorage.removeItem("usertoken");
    this.router.navigateByUrl("/");
  }
}
